#web-component {
    background-size: contain;
    background-repeat: no-repeat;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        img:first-child {
            width: 10rem; }
        .separator {
            background-color: #0075FF;
            min-height: 22px;
            width: 2px;
            margin: 0 1rem; }
        img:nth-child(3) {
            width: 9rem; } }
    .row {
        display: grid;
        grid-template-columns: 50% 50%;
        .config-wrapper, .iframe-wrapper {
            display: flex;
            align-items: center;
            padding: .5rem 1.5rem;
            .card-config, .iframe {
                display: flex;
                width: 100%;
                padding: 1.5rem;
                flex-direction: column;
                align-items: flex-start;
                gap: .75rem;
                border-radius: 0.875rem;
                background: linear-gradient(90deg, #24A5FF 0.37%, #3560F0 99.58%);
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10); }
            .card-config {
                justify-content: center;
                h2 {
                    color: white;
                    font-weight: 700;
                    font-size: 1.25rem; }
                h4 {
                    color: var(--colors-primary-on-background, #FFF);
                    font-size: 1rem;
                    font-weight: 600; }
                .filters {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    gap: .75rem;
                    font-size: .75rem;
                    .checkbox-wrapper {
                        display: flex;
                        justify-content: space-between;
                        color: white;
                        .option {
                            display: inline-block;
                            margin-right: 10px;
                            cursor: pointer;
                            text-align: center;
                            width: 33.3%;
                            padding-bottom: .75rem;
                            border-bottom: 2px solid transparent;
                            border-radius: 0.3rem 0.3rem 0 0; }
                        .selected {
                            font-weight: bold;
                            border-bottom: 2px solid #54C08B; } }
                    .settings-wrapper {
                        display: flex;
                        flex-direction: column;
                        gap: .75rem;
                        color: white;
                        .toggle-col {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 0.375rem;
                            background-color: #477CC8;
                            padding: 1.25rem 1rem;
                            div {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: .5rem; } }
                        .double-col {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-column-gap: .75rem; }
                        .colors-box {
                            .color-circle {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                border-radius: 0.375rem;
                                background-color: #477CC8;
                                padding: 1rem 1.5rem;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: .5rem; } }
                        .font-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 0.375rem;
                            background-color: #477CC8;
                            padding: 1rem 1.5rem;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: .75rem; }
                        .justified-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-radius: 0.375rem;
                            background-color: #477CC8;
                            padding: 1rem 1.5rem;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: .5rem;
                            div {
                                display: flex;
                                gap: .5rem;
                                height: 2rem; } }
                        .link-box {
                            display: flex;
                            align-items: baseline;
                            border-radius: 0.375rem;
                            background-color: #477CC8;
                            padding: .5rem 1.5rem;
                            gap: .5rem;
                            input {
                                width: 100%;
                                padding: 0;
                                background: transparent;
                                border: none;
                                outline: none;
                                color: #3CE9F4; } } }
                    .btn {
                        border-radius: 5rem;
                        background: #28C76F;
                        padding: 1.5rem 0;
                        margin-top: 0; } } } }


        .iframe-wrapper {
            .iframe {
                height: 50%; } } } }

@media screen and (min-width: 1025px) {
    #web-component {
        .row {
            .config-wrapper, .iframe-wrapper {
                padding: 1rem 2.5rem;
                .card-config, .iframe {
                    padding: 2rem;
                    gap: 1rem;
                    h2 {
                        font-size: 1.5rem; }
                    h4 {}
                    .filters {
                        margin-top: 1rem;
                        font-size: 1rem;
                        .settings-wrapper {
                            font-size: .75rem; } } } } } } }


@media screen and (min-width: 1366px) {
    #web-component {
        background-size: contain;
        display: grid;
        grid-template-rows: auto 1fr; } }


#web-component.main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-image: url("../../assets/banner2.jpg");
    background-size: cover;
    .card {
        width: 100%;
        max-width: 500px;
        height: fit-content;
        background-color: #fff;
        border-radius: 1rem;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__title {
            font-size: 1.5rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center; }
        .config {
            margin-top: 2rem;
            width: 100%;
            .config-item {
                width: 100%;
                background-color: #eee;
                padding: 1rem;
                border-radius: 1rem;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                &:last-child {
                    margin-bottom: 0; }
                &:hover {
                    background-color: #ddd; }
                &__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-width: 100%;
                    &__close {
                        background-color: #272727;
                        color: #eee;
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 1rem; }
                    &__title {
                        font-size: 1.25rem;
                        font-weight: 600; } }
                &__content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    justify-content: flex-start;
                    margin-top: 0.5em;
                    .checkbox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 1em;
                        font-size: 1.1rem;
                        label {
                            margin-left: 0.5em;
                            margin-bottom: 0;
                            color: #3d3d3d; }
                        input {
                            transform: scale(1.5);
                            border-radius: 50%;
                            overflow: hidden; }
                        &:last-child {
                            margin-right: 0; } } }
                #checkboxes {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 1rem; }
                .twitter-picker {
                    width: 100%!important;
                    margin-top: 1rem; } } } }
    .input-wrapper {
        display: flex;
        width: 100%;
        margin-top: 1em;
        input {
            width: 100%;
            font-size: 1.15rem;
            padding: 1rem; }
        select {
            border-radius: 0.5em;
            margin-right: 0.5em;
            padding: 1rem;
            width: 100%;
            font-size: 1.15rem; } }
    #iframe {
        width: 100%;
        height: 90%;
        max-width: 100%;
        margin-left: 2rem;
        background-color: #fff;
        border-radius: 1rem;
        overflow: hidden;

        iframe {
            width: 100%;
            height: 100%; }
        .iframe-placeholder {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 2rem 8vw;
            &__title {
                line-height: 1.5;
                font-size: 2.5rem;
                text-align: center;
                span {
                    font-weight: 600; } }
            .browser-mock-text {
                margin-top: 3rem;
                font-size: 1.5rem; }
            .browser-mock {
                width: 100%;
                img {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%; } } } } }
