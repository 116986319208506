*, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0; }

#root {
    height: 100vh; }

.main-container {
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-image: url("../../assets/banner.png");
    .left-wrapper {
        display: flex;
        flex-direction: column;
        padding: 3rem 0 5rem 0;
        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 3rem;
            img:first-child {
                width: 8.5rem; }
            .separator {
                background-color: #0075FF;
                min-height: 22px;
                width: 2px;
                margin: 0 1rem; }
            img:nth-child(3) {
                width: 8rem; } }
        .form-wrapper {
            display: grid;
            gap: 2rem;
            flex-grow: 1;
            border-radius: 14px;
            padding: 1rem 1.5rem;
            border: 1px solid white;
            background: var(--colors-primary-on-background, #FFF);
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.10);
            opacity: .85;
            .titles {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .form-title {
                    color: #2E2E2E;
                    max-width: 18ch;
                    align-self: center;
                    text-align: center;
                    font-size: 1.75rem;
                    font-weight: 700;
                    line-height: 2rem;
                    margin-bottom: .5rem; }
                .form-subtitle {
                    max-width: 34ch;
                    font-size: .75rem;
                    align-self: center;
                    text-align: center;
                    line-height: 149%;
                    a {
                        color: #0288D2;
                        font-weight: 700;
                        text-decoration-line: underline;
                        text-underline-offset: 0.2rem; } } }
            form {
                padding: 0 3rem;
                .input-wrapper {
                    display: flex;
                    flex-direction: column;
                    input {
                        padding: .75rem .75rem; } }
                .input-wrapper:first-child {
                    margin-bottom: 1rem; }
                .btn {
                    border-radius: 4.375rem;
                    background: #28C76F; } }
            p {
                color: #2A1B46;
                text-align: center;
                font-size: .75rem;
                line-height: normal; }
            .medium-text {
                a {
                    text-underline-offset: 0.2rem; } } } }
    .rigth-wrapper {
        display: none;
        .content-wrapper {
            height: fit-content;
            display: flex;
            flex-direction: column;
            text-align: center;
            .illustration-container {
                padding-top: 5rem;
                position: relative;
                .loader {
                    position: absolute;
                    top: 20%;
                    left: 45%; } } } } }

.loading-main-container {
    height: 100vh;
    .loading-logo-wrapper {
        width: 100%;
        height: 10%;
        margin: 0 auto 2rem;
        padding: 2rem 3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #fff;
        img {
            height: 2.5rem; } }
    .content-wrapper {
        height: fit-content;
        display: flex;
        flex-direction: column;
        text-align: center;
        .welcome-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 0 5rem 2rem; }
        .illustration-container {
            padding-top: 3rem;
            position: relative;
            .loader {
                position: absolute;
                top: 24%;
                left: 47.5%;
                border: 4px solid #D8DEED;
                border-top: 4px solid #0288D2; }
            img {
                width: 35%; }
            .loading-main-title {
                font-size: 2.25rem;
                line-height: 2.743rem;
                margin-bottom: 1rem;
                font-weight: $medium; } } } }

@media screen and (min-width: 1025px) {
    .main-container {
        display: grid;
        grid-template-columns: 45% 55%;
        padding-left: 4rem;
        background-image: none;
        .left-wrapper {
            padding: 3rem 0;
            .logo-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0rem;
                img:first-child {
                    width: 10rem; }
                .separator {
                    background-color: #0075FF;
                    min-height: 22px;
                    width: 2px;
                    margin: 0 1rem; }
                img:nth-child(3) {
                    width: 9rem; } }
            .form-wrapper {
                opacity: 1;
                align-self: center;
                padding: 2rem 2.5rem;
                flex-grow: 0;
                margin: auto 0;
                form {
                    padding: 0 1.5rem;
                    height: fit-content; } } }
        .rigth-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
                max-height: 100%;
                height: 100%;
                width: 125%; }
            .img-bag {
                position: absolute;
                width: 50%;
                height: auto; } } }

    .loading-main-container {
        .content-wrapper {
            .illustration-container {
                .loader {
                    top: 22%;
                    left: 47.5%;
                    border: 5px solid #D8DEED;
                    border-top: 5px solid #0288D2; } } } } }


@media screen and (min-width: 1366px) {
    .main-container {
        padding-left: 2rem;
        .left-wrapper {
            padding: 3rem 0;
            .logo-wrapper {
                img:first-child {
                    width: 12rem; }
                .separator {
                    min-height: 26px;
                    width: 2px;
                    margin: 0 1.5rem; }
                img:nth-child(3) {
                    width: 10.5rem; } }
            .form-wrapper {
                padding: 3rem 2.5rem;
                flex-grow: 0;
                margin: auto 0;
                .titles {
                    .form-title {
                        font-size: 2.25rem;
                        margin-bottom: 1rem; }
                    .form-subtitle {
                        font-size: 1rem; } }
                form {
                    padding: 0 2rem; }
                p {
                    font-size: 1rem; } } }
        .rigth-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            img {
                max-height: 100%;
                height: 100%;
                width: 125%; }
            .img-bag {
                position: absolute;
                width: 50%;
                height: auto; } } } }

.loading-main-container {
        .content-wrapper {
            .illustration-container {
                .loader {
                    top: 21%; } } } }


@media screen and (min-width: 1440px) {
    .loading-main-container {
        .content-wrapper {
            .illustration-container {
                .loader {
                    width: 4rem;
                    height: 4rem;
                    top: 19%;
                    left: 47.25%;
                    border: 6px solid #D8DEED;
                    border-top: 6px solid #0288D2; } } } } }
