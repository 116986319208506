.swal-container-error,
.swal-container-success {
    font-family: $main-font;
    width: 45vw;
    height: 83px;
    background-color: #F5E6E9;
    border-radius: 15px;
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    top: 0%;
    right: 0%;
    margin-top: 60px;
    margin-right: 65px;
    .swal-icon {
        width: 26px;
        height: 27px;
        margin-right: 20px; }
    .swal-title-error,
    .swal-title-success {
        color: $text-red;
        font-weight: $bold;
        font-size: 24px;
        line-height: 29.26px;
        margin-right: 20px; }
    .swal-text {
        text-align: left;
        font-size: 18px;
        line-height: 21.94px;
        width: 75%; } }

.swal-container-success {
    background-color: #F2F7E6;
    justify-content: flex-start;
    .swal-title-success {
        color: $text-green; } }


@media screen and ( max-width: 1367px ) {
    .swal-container-error,
    .swal-container-success {
        height: 60px;
        padding: 10px 20px;
        margin-top: 30px;
        margin-right: 60px;
        .swal-icon {
            width: 22px;
            height: 23px;
            margin-right: 10px; }
        .swal-title-error,
        .swal-title-success {
            font-size: 18px;
            line-height: 23px;
            margin-right: 10px; }
        .swal-text {
            text-align: left;
            font-size: 14px;
            line-height: 17px;
            width: 75%; } } }

@media screen and ( max-width: 1025px ) {
    .swal-container-error,
    .swal-container-success {
        width: 45vw;
        height: 60px;
        padding: 10px 20px;
        margin-top: 15px;
        margin-right: 40px; } }
