
.switch {
	width: 4em;
	background-color: #c7c7c7;
	height: 1.75em;
	border-radius: 1em;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
	border: 1px solid #d8d8d8;
	.status-ball {
		width: 2em;
		height: 2em;
		border-radius: 50%;
		background-color: #ffffff;
		transition: all 0.1s ease-in-out;
		position: absolute;
		left: 0;
		border: 1px solid #c7c7c7;
		
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffd13b !important;
		background-color: #1ea7fd !important;
		border-color: #1ea7fd !important;
		svg {
			font-size: 1.25rem;
		}
	}
	&.on {
		background-color: #65889e !important;
		border-color: #65889e !important;
		.status-ball {
			color: #fff !important;
			background-color: #0a4264 !important;
			border-color: #0a4264 !important;
			left: 2em
		}
	}
}
