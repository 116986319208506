body {
    font-family: $main-font;
    color: $text-black;
    letter-spacing: -0.43px;
    .main-title,
    .loading-main-title {
        font-size: 2.25rem;
        line-height: 2.743rem;
        margin-bottom: 1rem;
        font-weight: $medium;
        color: $white; }
    .loading-main-title {
        color: $text-black;
        font-weight: $bold; }
    .subtitle,
    .loading-subtitle {
        font-size: 1.36rem;
        line-height: 1.829rem;
        font-weight: $regular;
        color: $white; }
    .loading-subtitle {
        color: $text-black; }
    .small-title {
        font-size: 1.5rem;
        line-height: 1.829rem;
        font-weight: $regular;
        margin-bottom: 1rem;
        width: 100%; }
    .medium-text {
        font-size: 1.125rem;
        line-height: 1.371rem;
        font-weight: $regular; }
    .small-text {
        font-size: 0.875rem;
        line-height: 1.067rem;
        font-weight: $medium;
        width: 100%; } }

@media screen and ( max-width: 1921px ) {
    body {
        .main-title,
        .loading-main-title {
            font-size: 2.7rem; }
        .small-title {
            font-size: 1.8rem; }
        .small-text {
            font-size: 1rem;
            line-height: 1.3rem; } } }

@media screen and ( max-width: 1441px ) {
    body {
        .main-title,
        .loading-main-title {
            font-size: 2.25rem; }
        .small-title {
            font-size: 1.4rem;
            margin-bottom: 0.5rem; }
        .medium-text {
            font-size: 1rem; }
        .small-text {
            font-size: 0.875rem;
            line-height: 1.067rem; } } }

@media screen and ( max-width: 1537px ) {
    body {
        .main-title {
            font-size: 2.2rem; }
        .small-title {
            font-size: 1.5rem; } } }

@media screen and ( max-width: 1367px ) {
    body {
        .main-title,
        .loading-main-title {
            font-size: 1.75rem;
            line-height: 2.188rem;
            margin-bottom: 1rem; }
        .loading-main-title {
            margin-bottom: 0.5rem; }
        .subtitle,
        .loading-subtitle {
            font-size: 1.125rem;
            line-height: 1.438rem; }
        .small-title {
            font-size: 1.1rem;
            line-height: 1.5rem; }
        .medium-text {
            font-size: 0.875rem;
            line-height: 1.063rem; }
        .small-text {
            font-size: 0.75rem;
            line-height: 0.938rem; } } }

@media screen and ( max-width: 1025px ) {
    body {
        .main-title,
        .loading-main-title {
            font-size: 1.5rem;
            line-height: 1.938rem;
            margin-bottom: 0.5rem; }
        .loading-main-title {
            margin-bottom: 0.3rem; }
        .subtitle,
        .loading-subtitle {
            font-size: 1rem;
            line-height: 1.313rem; } } }
