#refund-modal {
    .modal-body {
        .refund-type {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
            .refund-type-item {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $gray1;
                padding: 1.5rem 0;
                font-size: 1.35rem;
                transition: all 0.3s ease-in-out;
                border-radius: 0 5rem 5rem 0;
                border: 2px solid $gray1;
                &:first-child {
                    border-radius: 5rem 0 0 5rem; }
                &:hover {
                    background-color: $soft-orange;
                    color: $orange2;
                    border: 2px solid $orange1;
                    cursor: pointer; } }
            .active {
                background-color: $orange1;
                color: $white;
                border: 2px solid $orange1;
                cursor: pointer; } }
        .order-items {
            width: 100%;
            .order-item {
                display: flex;
                align-items: center;
                margin: 0.5rem 0;
                width: 100%;
                padding: 1rem;
                border: 1px solid #fff;
                border-radius: 0.5rem;
                position: relative;
                overflow: hidden;
                transition: all 0.4s ease;
                border: 1px solid $gray2;

                .order-item-image {
                    width: 3rem;
                    height: 3rem;
                    margin-right: 0.5rem;
                    img {
                        width: 100%; } }
                .order-item-details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    .order-item-name {
                        margin-right: 0.5rem;
                        width: 100%; }
                    .order-item-quantity {
                        margin-left: 0.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        select {
                            padding: 0 0.5rem;
                            margin-right: 0.5rem;
                            width: 4rem;
                            height: 2rem;
                            border-radius: 3rem;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $accent;
                            border: $accent solid 1px; } }
                    .order-item-price {
                        margin-left: 0.5rem; } } } } }

    .modal-footer {
        margin-top: 2rem;
        .total-refund {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.75rem;
            margin-top: 2rem;
            span {
                margin-left: 1rem;
                font-weight: 600; } }
        #confirm-refund {
            margin-top: 1rem;
            background-color: $text-green;
            color: $white; } } }
