.btn {
    padding: 0;
    border: none;
    font: inherit;
    color: $white;
    background-color: $accent;
    cursor: pointer;
    width: 100%;
    padding: 1.5rem 0;
    margin-top: 2rem;
    font-size: 1.125rem;
    font-weight: $bold;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: $hovered; }
    &:active {
        background-color: $activated; }
    &.disabled {
        background-color: $disabled;
        cursor: initial; } }

.btn-link {
    font-weight: $bold;
    color: $accent-blue;
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
    &:hover {
        color: $hovered-blue; }
    &:active {
        color: $text-black; } }

@media screen and ( max-width: 1367px ) {
    .btn {
        padding: 1.25rem 0;
        margin-top: 1.3rem;
        font-size: 0.875rem; } }

@media screen and ( max-width: 1025px ) {
    .btn {
        margin-top: 1rem; } }
