button {
    background-clip: none;
    box-shadow: transparent;
    background-color: $orange2;
    border: none;
    border-radius: 0.25rem;
    height: 2rem;
    color: $white;
    font-size: 1rem;
    padding: 0 1rem;
    transition: all 0.2s ease-in-out;
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.1); }
    svg {
        font-size: 2rem;
        margin-right: 0.5rem; } }
.xl {
    font-size: 1.35rem; }
.disabled {
    pointer-events: none;
    opacity: 0.5; }
