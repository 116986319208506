.order {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    min-height: 2.25rem;
    border-radius: 0.5rem;
    border: 1px solid $gray1;
    transition: all 0.3s ease-in-out;
    width: 100%;
    &:hover {
        background-color: $gray0;
        border: 1px solid $gray1; }

    &:first-child {
        margin-top: 0; }
    #actions {
        .btn {
            padding: 0;
            font-size: 1rem;
            margin-top: 0;
            font-weight: 400;
            background-color: $yellow;
            width: 48%;
            min-width: fit-content;
            svg {
                font-size: 1.35rem;
                margin-right: 0.5rem; } }
        #refund-order {
            margin-right: 0;
            background-color: $purple; } }

    #status {
        padding: 0.5rem 0;
        border-radius: 0.5rem; }
    .paid {
        background-color: $soft-green;
        color: $text-green!important; }
    .failed {
        background-color: $soft-red;
        color: $text-red!important; }
    .refunded {
        background-color: $soft-purple;
        color: $purple!important; } }
