#order-details {
    .modal-body {
        .order-details {
            border-radius: 0.5rem;

            .customer {
                background-color: $gray2;
                padding: 1rem;
                margin-bottom: 1rem;
                border-radius: 0 0 0.5rem 0.5rem;
                span {
                    font-weight: 600;
                    margin-right: 0.5rem; }
                .customer-name {
                    margin-bottom: 0.5rem; }
                .customer-contact {
                    width: 100%;
                    margin: 0.75rem 0;
                    display: flex;
                    align-items: center;
                    .customer-contact-wrapper {
                        display: flex;
                        width: 100%;
                        background-color: $gray1;
                        border: 1px solid $gray3;
                        padding: 0.5rem 1rem;
                        border-radius: 0.5rem;
                        align-items: center;
                        justify-content: center;
                        .customer-contact-field {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            min-width: fit-contentt;
                            svg {
                                margin-right: 0.5rem;
                                color: $gray6; }
                            &:last-child {
                                margin-left: 0.5rem; } } } } }

            .order-items {
                width: 100%;
                background-color: $gray2;
                .order-item {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 1rem;
                    position: relative;
                    overflow: hidden;
                    transition: all 0.4s ease;
                    .order-item-refunded {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.35);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 1.25rem;
                        transition: all 0.4s ease;
                        z-index: 9999;
                        svg {
                            font-size: 2rem;
                            margin-right: 0.5rem; } }
                    .order-item-hover {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 1.25rem;
                        cursor: pointer;
                        opacity: 0;
                        transition: all 0.4s ease;
                        svg {
                            font-size: 2rem;
                            margin-right: 0.5rem; } }
                    .order-item-image {
                        width: 3rem;
                        height: 3rem;
                        margin-right: 0.5rem;
                        img {
                            width: 100%; } }
                    .order-item-details {
                        display: flex;
                        width: 100%;
                        .order-item-name {
                            margin-right: 0.5rem;
                            width: 100%; }
                        .order-item-quantity {
                            margin-left: 0.5rem; }
                        .order-item-price {
                            margin-left: 0.5rem; } }
                    &:hover {
                        .order-item-hover {
                            opacity: 1; } } } }
            #refund-order {
                background-color: $purple; }

            .order-total {
                border-top: 1px solid $gray2;
                margin-bottom: 0.5rem;
                font-weight: 600;
                border-radius: 0 0 0.5rem 0.5rem;
                padding: 1rem 0.5rem;
                border: 1px solid $gray2;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 1.5rem;
                .order-refunded {
                    color: $purple;
                    font-size: 1rem;
                    padding: 0.5rem 1rem;
                    margin-top: 0.5rem;
                    background-color: $soft-purple;
                    border-radius: 0.5rem;
                    span {
                        font-size: 1.25rem; } }
                span {
                    font-size: 1.75rem;
                    margin-left: 0.5rem;
                    transition: all 0.4s ease; }
                &:hover {
                    span {
                        transform: scale(1.1); } } } } } }
