
#panel.main-container {
    background-color: $soft-yellow;
    background-image: url("../../assets/panel-banner.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    min-height: 80%;
    width: 100%;
    padding: 2rem 7vw;
    min-height: 100%;

    .panel-container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        width: 100%;

        background-color: $white;
        border-radius: 1rem;
        overflow: hidden;
        position: relative;
        min-height: 75vh;
        //Panel Header
        .panel-header {
            padding: 2rem;
            background-color: $yellow;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text {
                font-size: 2vw;
                color: $white;
                span {
                    font-weight: 600; } }
            .panel-title {
                color: $gray3;
                font-size: 1.5rem;
                font-weight: bold;
                padding: 1rem;
                text-align: center; }
            .logos {
                max-width: 40%;
                img {
                    width: 47.5%;
                    &:last-child {
                        margin-left: 5%; } } } }

        //Panel Body
        .panel-body {
            padding: 2rem;
            padding-bottom: 4rem;
            width: 100%;
            height: fit-content;
            background-color: #fff;
            max-height: 100%;
            min-height: 100%;

            //Panel Body Header
            .panel-body-header {
                width: 100%;
                height: 2.5rem;
                display: flex;
                background-color: $soft-yellow;
                border-radius: 0.5rem;
                font-weight: 600;
                color: $text-black;
                padding: 0 1rem;
                select {
                    width: 100%;
                    border: 1px solid $yellow;
                    background-color: transparent;
                    height: 1.75rem;
                    border-radius: 0.25rem;
                    padding: 0 0.5rem;
                    color: $gray6;
                    outline-color: $yellow; } }

            //Panel Body Content
            .panel-body-body {
                min-height: 88%;
                overflow-y: auto;
                padding: 1rem 0;
                width: 100%; }

            //Panel Body Footer
            .panel-body-footer {
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 3rem;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 2rem;
                .results-per-page {
                    color: $gray6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    select {
                        padding: 0 0.5rem;
                        margin-right: 1rem;
                        width: 4rem;
                        height: 2rem;
                        border-radius: 10rem;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $gray6;
                        border: $orange1 solid 1px;
                        outline: $orange1;
                        background-color: transparent; } }

                .pagination {
                    display: flex;
                    border-radius: 4rem;
                    border: 1px solid $orange1;
                    .pagination-item {
                        width: 2rem;
                        height: 2rem;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $orange1;
                        &:hover {
                            background-color: $orange1;
                            color: $white; } }
                    .active {
                        background-color: $orange1;
                        color: $white; } } }
            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                .col {
                    height: 2rem;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-right: 0.5rem;
                    color: $gray6;
                    input {
                        padding: 0.25rem 0.5rem;
                        width: 100%;
                        border: 1px solid $yellow;
                        background-color: transparent;
                        height: 1.75rem;
                        border-radius: 0.25rem;
                        color: $gray6;
                        outline-color: $orange2; }
                    &:last-child {
                        margin-right: 0; } }

                //Row Specifications
                #order-number {
                    max-width: 8rem; }
                #date {
                    min-width: fit-content;
                    max-width: 8rem;
                    .react-datepicker__close-icon {
                        &::after {
                            background-color: $orange2; } }
                    .react-datepicker-wrapper {
                        max-width: 7rem; } }

                #name {
                    min-width: fit-content;
                    input {
                        max-width: 50%;
                        &::placeholder {
                            color: $gray6; } } }
                #status {
                    min-width: fit-content;
                    max-width: 9rem;
                    justify-content: center; }
                #total {
                    max-width: 7.5rem; }
                #actions {
                    min-width: fit-content;
                    max-width: 20rem;
                    justify-content: space-between;
                    margin-left: 2rem; } } } }
    .disabled {
        opacity: 0.5;
        pointer-events: none; }
    //Media Queries
    @media (max-width: 1400px) {
        padding: 2rem; }
    @media (max-width: 1200px) {
        padding: 1rem 2rem;
        .panel-container {
            .panel-body {
                padding: 1.5rem;
                padding-bottom: 4rem;
                .panel-body-footer {
                    padding: 1.5rem; }
                .row {
                    #name {
                        input {
                            max-width: 100%; } }
                    #total {
                        display: none; } } } } }

    @media (max-width: 1024px) {
        .panel-container {
            .panel-header {
                .text {
                    font-size: 3vw; } }

            .panel-body {
                padding: 1rem;
                padding-bottom: 4rem;
                .panel-body-footer {
                    padding: 1rem; }
                .row {
                    #order-number {
                        max-width: 6rem; }
                    #date {
                        display: none; } } } } }

    @media (max-width: 850px) {
        .panel-container {
            .panel-body {
                .row {
                    #name {
                        display: none; }
                    #actions {
                        .btn {
                            svg {
                                margin-right: 0; }
                            span {
                                display: none; } } } } } } }

    @media (max-width: 550px) {
        .panel-container {
            .panel-body {
                .row {
                    #status {
                        display: none; } } } } } }
