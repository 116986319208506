//Spinner

//  .spinner
//  margin: 0
//  width: 70px
//  text-align: center

.spinner > div {
    width: 12px;
    height: 12px;
    background-color: $white;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner {
    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s; } }

.spinner {
    .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s; } }

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0); }
    40% {
      -webkit-transform: scale(1.0); } }

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0); }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0); } }

@media screen and ( max-width: 1367px ) {
    .spinner > div {
        width: 8px;
        height: 8px; } }

//Spinner2

.loader {
  border: 14px solid $background-loader;
  border-top: 14px solid $accent-blue;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 1.5s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


@media screen and ( max-width: 1367px ) {
    .loader {
        border: 8px solid $background-loader;
        border-top: 8px solid $accent-blue;
        width: 50px;
        height: 50px; } }

@media screen and ( max-width: 1025px ) {
    .loader {
        border: 6px solid $background-loader;
        border-top: 6px solid $accent-blue;
        border-radius: 50%;
        width: 40px;
        height: 40px; } }
