.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
        width: 100%;
        min-width: fit-content;
        max-width: 40rem;
        height: fit-content;
        max-height: 95%;
        min-height: 20%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.025), 0 2px 4px 0 rgba(0,0,0,0.025);
        z-index: 99999;
        padding: 2rem;
        position: relative;

        .close-button {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            z-index: 99999;
            cursor: pointer;
            svg {
                font-size: 1.5rem; } }
        .modal-title {
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #e0e0e0;
            span {
                font-weight: 600;
                color: $orange1; } }
        .modal-section-title {
            padding: 1rem;
            background-color: $gray0;
            color: $gray6;
            border-radius: 0.5rem 0.5rem 0 0;
            border: 1px solid $gray2; } }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); } }
